<template>
  <div class="image-manager">
    <div class="title">
      <h3>{{ getName }}</h3>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      image: {
        type: Object,
      },
    },

    computed: {
      getId() {
        return this.image.id;
      },

      getName() {
        return this.image.name ?? "Untitled";
      },
    },
  };
</script>
