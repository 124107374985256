<template>
  <div class="main-content layout">
    <div class="side">
      <ul>
        <li v-for="page in this.pages" :key="page">
          <button @click="select(page)" :disabled="page == this.page">
            {{ page }}
          </button>
        </li>
      </ul>
    </div>
    <div class="main">
      <AdminVenues v-if="this.page == 'Venues'" />
      <AdminVendors v-if="this.page == 'Vendors'" />
      <AdminOutlets v-if="this.page == 'Outlets'" />
    </div>
  </div>
</template>

<script>
  import AdminVenues from "@/views/Admin/Entities/AdminVenues.vue";
  import AdminVendors from "@/views/Admin/Entities/AdminVendors.vue";
  import AdminOutlets from "@/views/Admin/Entities/AdminOutlets.vue";

  export default {
    data() {
      return {
        page: "Venues",
        pages: ["Venues", "Vendors", "Outlets"],
      };
    },

    components: {
      AdminVenues,
      AdminVendors,
      AdminOutlets,
    },

    computed: {},

    methods: {
      select(page) {
        this.page = page;
      },
    },
  };
</script>

<styles lang="scss" scoped>
.layout {
  padding: 0;
  display: flex;
  flex-direction: row;

  .side {
    padding: 1rem;
    width: 12rem;

    @include themed() {
      color: t($text);
      background: t($back);
      box-shadow: 0 0 6px t($shadow);
    }

    ul li button {
      width: 100%;
      margin-bottom: 0.2rem;
    }
  }

  .main {
    width: 100%;

    table,
    tr,
    th,
    td {
      border: 0;
      padding: 0;
      margin: 0;
      border-collapse: collapse;
    }

    th,
    td {
      border: 1px solid #bbb;
      padding-left: 5px;
      padding-right: 5px;
    }

    table {
      tbody {
        tr:nth-child(even) {
          background: #fff;
        }
        tr:nth-child(odd) {
          background: #eee;
        }
      }
    }
  }
}
</styles>
