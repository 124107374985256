<template>
  <div class="outer">
    <div class="controls">
      <span class="search">
        <label for="search">Search:</label>
        <input name="search" type="text" v-model="search" />
      </span>
    </div>
    <div class="body">
      <table>
        <thead>
          <th>Id</th>
          <th>Name</th>
          <th>Description</th>
          <th>Code</th>
          <th>Tax Number</th>
          <th>Walkups</th>
          <th>Thumbnails</th>
        </thead>
        <tbody>
          <tr v-for="vendor in filtered" :key="vendor.id">
            <td>{{ vendor.id }}</td>
            <td>{{ vendor.name }}</td>
            <td>{{ vendor.description }}</td>
            <td>{{ vendor.code }}</td>
            <td>{{ vendor.taxNumber }}</td>
            <td>{{ !vendor.disableWalkups }}</td>
            <td>
              <span
                v-for="(thumbnail, index) in vendor.thumbnails"
                :key="`${vendor.id}.${index}`"
                class="thumb">
                <img :src="getThumbnail(thumbnail)" :title="thumbnail.name" />
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
  import store from "@/store";
  import { useToast } from "vue-toastification";

  export default {
    data() {
      return {
        vendors: [],
        search: "",
      };
    },

    computed: {
      filtered() {
        let results = this.vendors;

        if (this.search ?? "" != "") {
          const regex = new RegExp(this.search, "gi");

          results = results.filter((x) => regex.test(x.name));
        }

        return results;
      },
    },

    methods: {
      getThumbnail(thumbnail) {
        return `${process.env.VUE_APP_CDN}/${thumbnail.cdnUrl}.webp`;
      },
    },

    mounted() {
      store.state.apiPrivate.client.endpoints.vendors
        .getAll()
        .then((response) => {
          if (response.status == 200) {
            return response.data.data;
          }

          return Promise.reject(response);
        })
        .then((data) => {
          this.vendors = data;
        })
        .catch((ex) => {
          console.error("Failed to download vendors.", ex);
          useToast().error("Failed to download vendors.");

          this.vendors = [];
        });
    },
  };
</script>

<style lang="scss" scoped>
  .outer {
    .controls {
      margin: 1rem;
    }

    .body {
      margin: 1rem;

      .thumb {
        margin-left: 1rem;
      }

      .thumb,
      .thumb img {
        width: 160px;
        height: 90px;
      }
    }
  }
</style>
