<template>
  <div class="outer">
    <div class="controls">
      <span class="search">
        <label for="search">Search:</label>
        <input name="search" type="text" v-model="search" />
      </span>
    </div>
    <div class="body">
      <table>
        <thead>
          <th>Id</th>
          <th>Name</th>
          <th>Description</th>
          <th>Code</th>
          <th>Mode</th>
          <th>Currency</th>
          <th>Location</th>
          <th>Thumbnails</th>
        </thead>
        <tbody>
          <tr v-for="venue in filtered" :key="venue.id">
            <td>{{ venue.id }}</td>
            <td>{{ venue.name }}</td>
            <td>{{ venue.description }}</td>
            <td>{{ venue.code }}</td>
            <td>{{ venue.mode }}</td>
            <td>{{ venue.currencyCode }}</td>
            <td>{{ venue.center != null }}</td>
            <td>
              <span
                v-for="(thumbnail, index) in venue.thumbnails"
                :key="`${venue.id}.${index}`"
                @click="showImageManager(thumbnail)"
                class="thumb">
                <img :src="getThumbnail(thumbnail)" :title="thumbnail.name" />
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

  <teleport to="body">
    <transition name="fade">
      <ModalDialog
        v-if="selectedImage != null"
        class="modal"
        @close="selectedImage = null"
        :maxWidth="'450'">
        <template v-slot:modal-title>Image Manager</template>

        <ImageManager :image="selectedImage" @close="selectedImage = null" />
      </ModalDialog>
    </transition>
  </teleport>
</template>

<script>
  import store from "@/store";
  import { useToast } from "vue-toastification";
  import ModalDialog from "@/components/Dialogs/ModalDialog.vue";
  import ImageManager from "@/components/Images/ImageManager.vue";

  export default {
    data() {
      return {
        venues: [],
        search: "",
        selectedImage: null,
      };
    },

    components: { ModalDialog, ImageManager },

    computed: {
      filtered() {
        let results = this.venues;
        if (this.search ?? "" != "") {
          const regex = new RegExp(this.search, "gi");
          results = results.filter((x) => regex.test(x.name));
        }
        return results;
      },
    },

    methods: {
      getThumbnail(thumbnail) {
        return `${process.env.VUE_APP_CDN}/${thumbnail.cdnUrl}.webp`;
      },

      showImageManager(thumbnail) {
        this.selectedImage = thumbnail;
      },
    },

    mounted() {
      store.state.apiPrivate.client.endpoints.venues
        .getAll()
        .then((response) => {
          if (response.status == 200) {
            return response.data.data;
          }
          return Promise.reject(response);
        })
        .then((data) => {
          this.venues = data;
        })
        .catch((ex) => {
          console.error("Failed to download venues.", ex);
          useToast().error("Failed to download venues.");
          this.venues = [];
        });
    },
  };
</script>

<style lang="scss">
  .outer {
    .controls {
      margin: 1rem;
    }

    .body {
      margin: 1rem;

      .thumb {
        margin-left: 1rem;
      }

      .thumb,
      .thumb img {
        width: 160px;
        height: 90px;
      }
    }
  }
</style>
